// Modules
import axios from 'axios';
import xhr from 'modules/xhr';
// Storage
import webStorage from 'modules/storage';
// API
import { ENDPOINTS, API_URL } from '../api.config';
// Interfaces and types
import {
  TResultParams,
  IGetFormsRes,
  ICreateFormBody,
  ICreateFormRes,
  IGetFormDetailsRes,
  IUpdateFormBody,
  IUpdateFormRes,
  IDeleteFormRes,
  IGetFormResultsRes,
  IGetFormResultDetailsRes,
  IFormResultsParams,
  IAddFormImageRes,
  IDeleteFormResultRes,
  IGetPublicFormDetailsRes,
  IDeleteFormImageRes,
  ISubmitPublicFormBody,
  ISubmitPublicFormRes,
  IAddPublicFormFileBody,
  IAddPublicFormFileRes,
  IDeletePublicFormFileBody,
  IDeletePublicFormFileRes,
  IPublicFormParams,
  IDeleteFormFileParam,
  IDeleteFormFileRes,
  IUploadFormFileParamsWithBody,
  IUploadFormFileRes,
  IUpdateFormResultParamsWithBody,
  IUpdateFormResultRes,
  IAddFormFilesRes,
  ISendFormResultEmailsBody,
  ISendFormResultEmailsRes,
  IGetFormResultReportParamsWithBody,
  IGetFormResultsAnalysisRes,
  IGetForResultsParams,
  IGetScheduleReportsRes,
  IGetScheduleReportsParams,
  IGetScheduleReportDetailsParams,
  IGetScheduleReportDetailsRes,
} from 'types/formTypes';
import { IResMessage, TQueryParams } from 'types/appTypes';
// I18n
import { getCurrentLanguage } from 'i18n';

export const getForms = (params: TQueryParams) => {
  return xhr.get<IGetFormsRes, IGetFormsRes>(`${ENDPOINTS.forms}`, { params });
};

export const createForm = (body: ICreateFormBody) => {
  return xhr.post<ICreateFormRes, ICreateFormRes>(`${ENDPOINTS.forms}`, body);
};

export const getFormDetails = (id: number) => {
  return xhr.get<IGetFormDetailsRes, IGetFormDetailsRes>(`${ENDPOINTS.forms}/${id}`);
};

export const updateForm = ({ id, ...body }: IUpdateFormBody) => {
  return xhr.patch<IUpdateFormRes, IUpdateFormRes>(`${ENDPOINTS.forms}/${id}`, body);
};

export const deleteForm = (formId: number) => {
  return xhr.delete<IDeleteFormRes, IDeleteFormRes>(`${ENDPOINTS.forms}/${formId}`);
};

export const getFormResults = ({ id, params }: IFormResultsParams) => {
  return xhr.get<IGetFormResultsRes, IGetFormResultsRes>(
    `${ENDPOINTS.forms}/${id}${ENDPOINTS.results}`,
    { params },
  );
};

export const deleteFormResult = ({ id, resultId }: TResultParams) => {
  return xhr.delete<IDeleteFormResultRes, IDeleteFormResultRes>(
    `${ENDPOINTS.forms}/${id}${ENDPOINTS.results}/${resultId}`,
  );
};

export const getFormResultDetails = ({ id, resultId }: TResultParams) => {
  return xhr.get<IGetFormResultDetailsRes, IGetFormResultDetailsRes>(
    `${ENDPOINTS.forms}/${id}${ENDPOINTS.results}/${resultId}`,
  );
};

export const addFormImage = (file: FormData) => {
  return xhr.post<IAddFormImageRes, IAddFormImageRes>(
    `${ENDPOINTS.forms}${ENDPOINTS.images}`,
    file,
  );
};

export const deleteFormImage = (id: string) => {
  return xhr.delete<IDeleteFormImageRes, IDeleteFormImageRes>(
    `${ENDPOINTS.forms}${ENDPOINTS.images}/${id}`,
  );
};

export const getPublicFormDetails = ({ accountName, urlTag }: IPublicFormParams) => {
  return xhr.get<IGetPublicFormDetailsRes, IGetPublicFormDetailsRes>(
    `${ENDPOINTS.companies}/${accountName}${ENDPOINTS.forms}/${urlTag}`,
  );
};

export const addFormBuilderFile = (file: FormData) => {
  return xhr.post<IAddFormFilesRes, IAddFormFilesRes>(
    `${ENDPOINTS.forms}${ENDPOINTS.files}`,
    file,
  );
};

export const deleteFormBuilderFile = (fileId: string) => {
  return xhr.delete<IResMessage, IResMessage>(
    `${ENDPOINTS.forms}${ENDPOINTS.files}/${fileId}`,
  );
};

export const addPublicFormFile = ({ accountName, urlTag, file }: IAddPublicFormFileBody) => {
  return xhr.post<IAddPublicFormFileRes, IAddPublicFormFileRes>(
    `${ENDPOINTS.companies}/${accountName}${ENDPOINTS.forms}/${urlTag}${ENDPOINTS.files}`,
    file,
  );
};

export const deletePublicFormFile = ({
  fileId,
  urlTag,
  accountName,
}: IDeletePublicFormFileBody) => {
  return xhr.delete<IDeletePublicFormFileRes, IDeletePublicFormFileRes>(
    `${ENDPOINTS.companies}/${accountName}${ENDPOINTS.forms}/${urlTag}${ENDPOINTS.files}/${fileId}`,
  );
};

export const submitPublicForm = ({ id, ...body }: ISubmitPublicFormBody) => {
  return xhr.post<ISubmitPublicFormRes, ISubmitPublicFormRes>(
    `${ENDPOINTS.forms}/${id}${ENDPOINTS.public}`,
    body,
  );
};

export const submitForm = ({ id, ...body }: ISubmitPublicFormBody) => {
  return xhr.post<ISubmitPublicFormRes, ISubmitPublicFormRes>(
    `${ENDPOINTS.forms}/${id}${ENDPOINTS.public}`,
    body,
  );
};

// for NOT public forms
export const addFormFile = ({ formId, resultId, file }: IUploadFormFileParamsWithBody) => {
  return xhr.post<IUploadFormFileRes, IUploadFormFileRes>(
    `${ENDPOINTS.forms}/${formId}${ENDPOINTS.results}/${resultId}${ENDPOINTS.files}`,
    file,
  );
};

export const deleteFormFile = ({ formId, resultId, fileId }: IDeleteFormFileParam) => {
  return xhr.delete<IDeleteFormFileRes, IDeleteFormFileRes>(
    `${ENDPOINTS.forms}/${formId}${ENDPOINTS.results}/${resultId}${ENDPOINTS.files}/${fileId}`,
  );
};

export const updateFormResult = ({
  formId,
  resultId,
  body,
}: IUpdateFormResultParamsWithBody) => {
  return xhr.put<IUpdateFormResultRes, IUpdateFormResultRes>(
    `${ENDPOINTS.forms}/${formId}${ENDPOINTS.results}/${resultId}`,
    body,
  );
};

export const getFormResultReport = ({ formId, body }: IGetFormResultReportParamsWithBody) => {
  const storageData = webStorage.getData();
  const language = getCurrentLanguage();

  return axios.get<Blob>(`${API_URL}${ENDPOINTS.forms}/${formId}${ENDPOINTS.resultsReport}`, {
    headers: {
      Authorization: `Bearer ${storageData.accessToken}`,
      Accept: 'application/json',
      'Accept-Language': language,
      'Content-Type': 'application/json',
    },
    responseType: 'blob',
    params: body,
  });
};

export const sendFormResultEmails = ({
  formId,
  resultId,
  body,
}: ISendFormResultEmailsBody) => {
  return xhr.post<ISendFormResultEmailsRes, ISendFormResultEmailsRes>(
    `${ENDPOINTS.forms}/${formId}${ENDPOINTS.results}/${resultId}/${ENDPOINTS.emails}`,
    body,
  );
};

export const getFormConstructorFile = (id: string) => {
  const storageData = webStorage.getData();
  return axios.get<Blob>(
    `${API_URL}${ENDPOINTS.forms}${ENDPOINTS.files}/${id}${ENDPOINTS.download}`,
    {
      headers: {
        Authorization: `Bearer ${storageData.accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
    },
  );
};

export const getFormResultsAnalysisRes = ({ params, id }: IGetForResultsParams) => {
  return xhr.get<IGetFormResultsAnalysisRes, IGetFormResultsAnalysisRes>(
    `${ENDPOINTS.forms}/${id}${ENDPOINTS.analysis}`,
    {
      params,
    },
  );
};

// Scheduled Report
export const getScheduleReports = ({ id, params }: IGetScheduleReportsParams) => {
  return xhr.get<IGetScheduleReportsRes, IGetScheduleReportsRes>(
    `${ENDPOINTS.forms}/${id}${ENDPOINTS.scheduleReports}`,
    { params },
  );
};

export const getScheduleReportDetails = ({
  formId,
  scheduleReportId,
}: IGetScheduleReportDetailsParams) => {
  return xhr.get<IGetScheduleReportDetailsRes, IGetScheduleReportDetailsRes>(
    `${ENDPOINTS.forms}/${formId}${ENDPOINTS.scheduleReports}/${scheduleReportId}`,
  );
};
