// Packages
import { createAsyncThunk } from '@reduxjs/toolkit';
// API
import { FormsAPI } from 'api/endpoints';
// Interfaces and types
import {
  TResultParams,
  IGetFormsRes,
  ICreateFormBody,
  ICreateFormRes,
  IGetFormDetailsRes,
  IUpdateFormBody,
  IUpdateFormRes,
  IDeleteFormRes,
  IGetFormResultsRes,
  IGetFormResultDetailsRes,
  IFormResultsParams,
  IDeleteFormResultRes,
  IGetFormResultsAnalysisRes,
  IGetForResultsParams,
  IGetScheduleReportsRes,
  IGetScheduleReportsParams,
  IGetScheduleReportDetailsParams,
  IGetScheduleReportDetailsRes,
} from 'types/formTypes';
import { IErrorRes, TQueryParams } from 'types/appTypes';

const getFormsAsync = createAsyncThunk<IGetFormsRes, TQueryParams, { rejectValue: IErrorRes }>(
  'form/getForms',
  async (params: TQueryParams, { rejectWithValue }) => {
    try {
      return await FormsAPI.getForms(params);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const createFormAsync = createAsyncThunk<
  ICreateFormRes,
  ICreateFormBody,
  { rejectValue: IErrorRes }
>('form/createForm', async (cred: ICreateFormBody, { rejectWithValue }) => {
  try {
    return await FormsAPI.createForm(cred);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const getFormDetailsAsync = createAsyncThunk<
  IGetFormDetailsRes,
  number,
  { rejectValue: IErrorRes }
>('form/getFormDetails', async (params: number, { rejectWithValue }) => {
  try {
    return await FormsAPI.getFormDetails(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const updateFormAsync = createAsyncThunk<
  IUpdateFormRes,
  IUpdateFormBody,
  { rejectValue: IErrorRes }
>('form/updateForm', async (cred: IUpdateFormBody, { rejectWithValue }) => {
  try {
    return await FormsAPI.updateForm(cred);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const deleteFormAsync = createAsyncThunk<IDeleteFormRes, number, { rejectValue: IErrorRes }>(
  'form/deleteForm',
  async (params: number, { rejectWithValue }) => {
    try {
      return await FormsAPI.deleteForm(params);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

const getFormResultsAsync = createAsyncThunk<
  IGetFormResultsRes,
  IFormResultsParams,
  { rejectValue: IErrorRes }
>('form/getFormResults', async (params: IFormResultsParams, { rejectWithValue }) => {
  try {
    return await FormsAPI.getFormResults(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const deleteFormResultAsync = createAsyncThunk<
  IDeleteFormResultRes,
  TResultParams,
  { rejectValue: IErrorRes }
>('form/deleteFormResult', async (params: TResultParams, { rejectWithValue }) => {
  try {
    return await FormsAPI.deleteFormResult(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const getFormResultDetailsAsync = createAsyncThunk<
  IGetFormResultDetailsRes,
  TResultParams,
  { rejectValue: IErrorRes }
>('form/getFormResultDetails', async (params: TResultParams, { rejectWithValue }) => {
  try {
    return await FormsAPI.getFormResultDetails(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const getFormResultsAnalysisAsync = createAsyncThunk<
  IGetFormResultsAnalysisRes,
  IGetForResultsParams,
  { rejectValue: IErrorRes }
>('form/formResultsAnalysis', async (params: IGetForResultsParams, { rejectWithValue }) => {
  try {
    return await FormsAPI.getFormResultsAnalysisRes(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const getScheduleReportsAsync = createAsyncThunk<
  IGetScheduleReportsRes,
  IGetScheduleReportsParams,
  { rejectValue: IErrorRes }
>('form/getScheduleReports', async (params: IFormResultsParams, { rejectWithValue }) => {
  try {
    return await FormsAPI.getScheduleReports(params);
  } catch (error) {
    return rejectWithValue(error as IErrorRes);
  }
});

const getScheduleReportDetailsAsync = createAsyncThunk<
  IGetScheduleReportDetailsRes,
  IGetScheduleReportDetailsParams,
  { rejectValue: IErrorRes }
>(
  'form/getScheduleReportDetails',
  async (params: IGetScheduleReportDetailsParams, { rejectWithValue }) => {
    try {
      return await FormsAPI.getScheduleReportDetails(params);
    } catch (error) {
      return rejectWithValue(error as IErrorRes);
    }
  },
);

export {
  getFormsAsync,
  createFormAsync,
  getFormDetailsAsync,
  updateFormAsync,
  deleteFormAsync,
  getFormResultsAsync,
  deleteFormResultAsync,
  getFormResultDetailsAsync,
  getFormResultsAnalysisAsync,
  getScheduleReportsAsync,
  getScheduleReportDetailsAsync,
};
