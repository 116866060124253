// I18n
import { t } from 'i18n';

export type ILabelSecondLevelMenu = keyof typeof modalsTitleSecondLevelMenuItem;
export const modalsTitleSecondLevelMenuItem = {
  formsPage: t('navModalsNames.newForm'),
  todoPage: t('navModalsNames.newTodo'),
};

export type ILabelThirdLevelMenu = keyof typeof modalsTitleThirdLevelMenuItem;
export const modalsTitleThirdLevelMenuItem = {
  companiesLocations: t('navModalsNames.newLocation'),
  companiesList: t('navModalsNames.newClient'),
  companiesContacts: t('navModalsNames.newContact'),
  companiesDocs: t('navModalsNames.newDoc'),
  locationsDocs: t('navModalsNames.newDoc'),
  jobsList: t('navModalsNames.newJob'),
  jobsGroups: t('navModalsNames.newJobGroup'),
  jobsRates: t('navModalsNames.addJobRate'),
  jobLog: t('navModalsNames.newJobLog'),
  jobExpenses: t('navModalsNames.newJobExpense'),
  employeeList: t('navModalsNames.newEmployee'),
  groups: t('navModalsNames.newEmployeeGroup'),
  log: t('navModalsNames.newEmployeeLog'),
  leave: t('navModalsNames.newEmployeeRequest'),
  rates: t('navModalsNames.newEmployeeRate'),
  docs: t('navModalsNames.newEmployeeDoc'),
  todo: t('navModalsNames.newEmployeeTodo'),
  equipmentList: t('navModalsNames.newEquipment'),
  equipmentLog: t('navModalsNames.newEquipmentLog'),
  equipmentExpenses: t('navModalsNames.newEquipmentExpense'),
  invoicesRecurring: t('navModalsNames.newRecurringInvoice'),
  invoiceRegister: t('navModalsNames.newOneTimeInvoice'),
  checkpointsList: t('navModalsNames.newCheckpoint'),
  companiesContactLogs: t('navModalsNames.newContactLog'),
};

export const getSubMenuThirdLevelItemName = (
  label: keyof typeof modalsTitleThirdLevelMenuItem,
) => {
  return modalsTitleThirdLevelMenuItem[label] ?? null;
};

export const getSubMenuSecondLevelItemName = (
  label: keyof typeof modalsTitleSecondLevelMenuItem,
) => {
  return modalsTitleSecondLevelMenuItem[label] ?? null;
};
