// Packages
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// Redux
import { clearReducer } from '../auth/authThunk';
import { saveVisitedPageLabelAsync, getMostVisitedPageLabelsAsync } from './navThunk';
import { IErrorRes } from 'types/appTypes';
import { INavHistory } from 'types/userTypes';

interface ISliceNavState {
  companiesLocations: boolean;
  companiesList: boolean;
  companiesContacts: boolean;
  companiesDocs: boolean;
  locationsDocs: boolean;
  jobsList: boolean;
  jobsGroups: boolean;
  jobsRates: boolean;
  jobLog: boolean;
  jobExpenses: boolean;
  employeeList: boolean;
  groups: boolean;
  log: boolean;
  leave: boolean;
  rates: boolean;
  docs: boolean;
  equipmentList: boolean;
  equipmentLog: boolean;
  equipmentExpenses: boolean;
  invoicesRecurring: boolean;
  invoiceRegister: boolean;
  formsPage: boolean;
  todoPage: boolean;
  checkpointsList: boolean;
  companiesContactLogs: boolean;
  navHistory: INavHistory[];
  isLoading: boolean;
  error: IErrorRes | null;
}

const initialState: ISliceNavState = {
  companiesLocations: false,
  companiesList: false,
  companiesContacts: false,
  companiesDocs: false,
  companiesContactLogs: false,
  locationsDocs: false,
  jobsList: false,
  jobsGroups: false,
  jobsRates: false,
  jobLog: false,
  jobExpenses: false,
  employeeList: false,
  groups: false,
  log: false,
  leave: false,
  rates: false,
  docs: false,
  checkpointsList: false,
  equipmentList: false,
  equipmentLog: false,
  equipmentExpenses: false,
  invoicesRecurring: false,
  invoiceRegister: false,
  formsPage: false,
  todoPage: false,
  navHistory: [],
  isLoading: false,
  error: null,
};

export type IPageName = keyof Omit<ISliceNavState, 'navHistory'>;

export interface IToggleNav {
  pageName: IPageName;
  shouldModalBeOpen: boolean;
}

const navSlice = createSlice({
  name: 'nav',
  initialState,
  reducers: {
    toggleModal: (state, { payload }: PayloadAction<IToggleNav>) => {
      state[payload.pageName] = payload.shouldModalBeOpen;
    },
    addCurrentPageToNav: (state, { payload }: PayloadAction<INavHistory>) => {
      state.navHistory = [];
      state.navHistory.push(payload);
    },
  },
  extraReducers: builder => {
    //* GET MOST POPULAR PAGES ASYNC THUNK
    builder.addCase(getMostVisitedPageLabelsAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getMostVisitedPageLabelsAsync.fulfilled, (state, { payload }) => {
      const formattedPagesWithoutCurrentPage = payload
        ?.filter(({ label }) => state.navHistory[0].label !== label)
        .slice(0, 7);

      if (state.navHistory.length === 1) {
        state.navHistory.push(...formattedPagesWithoutCurrentPage);
      }
    });
    builder.addCase(getMostVisitedPageLabelsAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* SAVE MOST POPULAR PAGES ASYNC THUNK
    builder.addCase(saveVisitedPageLabelAsync.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(saveVisitedPageLabelAsync.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(saveVisitedPageLabelAsync.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    //* CLEAR REDUCER AFTER SIGN OUT
    builder.addCase(clearReducer, () => initialState);
  },
});

export default navSlice;

export const { toggleModal, addCurrentPageToNav } = navSlice.actions;
