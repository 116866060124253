// Modules
import xhr from 'modules/xhr';
// API
import { ENDPOINTS } from '../api.config';
// Interfaces and types
import { IResMessage, TQueryParams } from 'types/appTypes';
import {
  IGetContactLogsRes,
  ICreateContactLogBody,
  ICreateContactLogRes,
  IGetContactTypes,
  IFile,
  IGetContactLogDetails,
  IUpdateContactLogBodyWithParam,
} from 'types/contactLogsTypes';

// Contact Types
export const getContactTypes = (params: TQueryParams) => {
  return xhr.get<IGetContactTypes, IGetContactTypes>(`${ENDPOINTS.contactTypes}`, {
    params,
  });
};

export const deleteContactType = (id: number) => {
  return xhr.delete<IResMessage, IResMessage>(`${ENDPOINTS.contactTypes}/${id}`);
};

// Contact Logs
export const createContactLog = (body: ICreateContactLogBody) => {
  return xhr.post<ICreateContactLogRes, ICreateContactLogRes>(
    `${ENDPOINTS.contactLogs}`,
    body,
  );
};

export const getContactLogDetails = (id: number) => {
  return xhr.get<IGetContactLogDetails, IGetContactLogDetails>(
    `${ENDPOINTS.contactLogs}/${id}`,
  );
};

export const getContactLogs = (params: TQueryParams) => {
  return xhr.get<IGetContactLogsRes, IGetContactLogsRes>(`${ENDPOINTS.contactLogs}`, {
    params,
  });
};

export const updateContactLog = ({ id, body }: IUpdateContactLogBodyWithParam) => {
  return xhr.put<IResMessage, IResMessage>(`${ENDPOINTS.contactLogs}/${id}`, body);
};

export const deleteContactLog = (id: number) => {
  return xhr.delete<IResMessage, IResMessage>(`${ENDPOINTS.contactLogs}/${id}`);
};

// Contact-logs files
export const addContactLogFile = (file: FormData) => {
  return xhr.post<IFile, IFile>(`${ENDPOINTS.contactLogsFiles}`, file);
};

export const deleteContactLogFile = (id: string) => {
  return xhr.delete<IResMessage, IResMessage>(`${ENDPOINTS.contactLogsFiles}/${id}`);
};
