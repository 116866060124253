// Core
import { CSSProperties, ReactNode } from 'react';
// Packages
import { Moment } from 'moment';
import { AiOutlineClockCircle, AiOutlineClose } from 'react-icons/ai';
import { TimePicker as AntTimePicker } from 'antd';
// Helpers
import { TIME_FORMAT, USE_12_HOURS } from 'helpers/data/constants';
// Styles
import styles from './timePicker.module.scss';

interface ITimePickerProps {
  allowClear?: boolean;
  autoFocus?: boolean;
  bordered?: boolean;
  className?: string;
  clearIcon?: ReactNode;
  dataCy?: string;
  defaultValue?: Moment;
  disabled?: boolean;
  disabledHours?: () => number[];
  disabledMinutes?: (hour: number) => number[];
  disabledSeconds?: (hour: number, minute: number) => number[];
  format?: string;
  getPopupContainer?: (node: HTMLElement) => HTMLElement;
  hideDisabledOptions?: boolean;
  hourStep?: number;
  inputReadOnly?: boolean;
  minuteStep?: number;
  open?: boolean;
  placeholder?: string;
  popupClassName?: string;
  popupStyle?: CSSProperties;
  renderExtraFooter?: () => ReactNode;
  secondStep?: number;
  size?: 'large' | 'middle' | 'small';
  showNow?: boolean;
  style?: CSSProperties;
  suffixIcon?: ReactNode;
  use12Hours?: boolean;
  value?: Moment;
  onChange?: (value: Moment | null, dateString: string) => void;
  onOpenChange?: (open: boolean) => void;
  onSelect?: (time: Moment) => void;
}

/**
 * To select or input time.
 *
 * @description By clicking the input box, you can select a time from a popup panel.
 *
 * @param allowClear - Whether allow clearing text
 * @param autoFocus - If get focus when component mounted
 * @param bordered - Whether has border style
 * @param className - The className of picker
 * @param clearIcon - The custom clear icon
 * @param defaultValue - To set default time
 * @param dataCy - Param for testing purpose;
 * @param disabled - Determine whether the TimePicker is disabled
 * @param disabledHours - To specify the hours that cannot be selected
 * @param disabledMinutes - To specify the minutes that cannot be selected
 * @param disabledSeconds - To specify the seconds that cannot be selected
 * @param format - To set the time format
 * @param getPopupContainer - To set the container of the floating layer, while the default is to create a div element in body
 * @param hideDisabledOptions - Whether hide the options that can not be selected
 * @param hourStep - Interval between hours in picker
 * @param inputReadOnly - Set the readonly attribute of the input tag (avoids virtual keyboard on touch devices)
 * @param minuteStep - Interval between minutes in picker
 * @param open - Whether to popup panel
 * @param placeholder - Display when there's no value
 * @param popupClassName - The className of panel
 * @param popupStyle - The style of panel
 * @param renderExtraFooter - Called from time picker panel to render some addon to its bottom
 * @param secondStep - Interval between seconds in picker
 * @param size - Size of TimePicker input
 * @param showNow - Whether to show Now button on panel
 * @param style - To customize the style
 * @param suffixIcon - The custom suffix icon
 * @param use12Hours - Display as 12 hours format, with default format h:mm:ss a
 */

const TimePicker = ({
  allowClear = false,
  autoFocus,
  bordered,
  className,
  clearIcon = <AiOutlineClose size={16} />,
  dataCy = '',
  defaultValue,
  disabled = false,
  disabledHours,
  disabledMinutes,
  disabledSeconds,
  format = TIME_FORMAT,
  getPopupContainer,
  hideDisabledOptions,
  hourStep,
  inputReadOnly,
  minuteStep,
  open,
  placeholder = '',
  popupClassName,
  popupStyle,
  renderExtraFooter,
  secondStep,
  size = 'middle',
  showNow,
  style,
  suffixIcon = <AiOutlineClockCircle size={16} />,
  use12Hours = USE_12_HOURS,
  value,
  onChange,
  onOpenChange,
  onSelect,
}: ITimePickerProps) => (
  <AntTimePicker
    allowClear={allowClear}
    autoFocus={autoFocus}
    bordered={bordered}
    className={`${styles.timePicker} ${className}`}
    clearIcon={clearIcon}
    data-cy={dataCy}
    defaultValue={defaultValue}
    disabled={disabled}
    disabledHours={disabledHours}
    disabledMinutes={disabledMinutes}
    disabledSeconds={disabledSeconds}
    format={format}
    getPopupContainer={getPopupContainer}
    hideDisabledOptions={hideDisabledOptions}
    hourStep={hourStep}
    inputReadOnly={inputReadOnly}
    minuteStep={minuteStep}
    open={open}
    placeholder={placeholder}
    popupClassName={popupClassName}
    popupStyle={popupStyle}
    renderExtraFooter={renderExtraFooter}
    size={size}
    secondStep={secondStep}
    showNow={showNow}
    style={style}
    suffixIcon={suffixIcon}
    use12Hours={use12Hours}
    value={value}
    onChange={onChange}
    onOpenChange={onOpenChange}
    onSelect={onSelect}
  />
);

export default TimePicker;
