// Packages
import { View, NavigateAction } from 'react-big-calendar';
// Modules
import storage from 'modules/storage';
// Configs
import dateConfig from 'configs/dateConfig';
// Types
import { TLang } from 'i18n/index';

export const IS_AM_PM_TIME_FORMAT = storage.getData('timeGoUse12Hours');
export const LOCALE = storage.getData<TLang>('timeGoLang')?.lng || 'en';

export const EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+\.)+[a-zA-Z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]{2,}))$/;
export const PASSWORD_PATTERN = /(?=.*\d)(?=.*[A-Z])(?=.*[-+_!@#$%^&*.,?])/;

export const CSV_EXAMPLE_LINK =
  'https://timego-public.s3.us-east-2.amazonaws.com/import-csv-example.csv';

export const DATE_FORMAT = 'DD-MMM-YYYY';
export const DATE_FORMAT_STANDARD = 'YYYY-MM-DD';

export const NUMBER_DATE_FORMAT = 'YYYY-MM-DD';
export const LONG_DATE_FORMAT = 'dddd, MMM DD';
export const SHORT_DATE_FORMAT = 'MMM DD';
export const TIME_FORMAT = dateConfig.appTimeFormat;
export const USE_12_HOURS = dateConfig.shouldUse12Hours;

export const DND_TASK_TYPE = 'task';
export const DND_COMPONENT_TYPE = 'formComponent';

export const NOT_FOUND = [
  'COMPANY_NOT_FOUND',
  'CONTACT_NOT_FOUND',
  'LOCATION_NOT_FOUND',
  'JOB_NOT_FOUND',
  'EMPLOYEE_NOT_FOUND',
  'PERFORMANCE_LOG_NOT_FOUND',
  'LEAVE_REQUEST_NOT_FOUND',
  'EMPLOYEE_RATE_NOT_FOUND',
  'EMPLOYEE_FILE_NOT_FOUND',
  'FORM_NOT_FOUND',
  'TODO_NOT_FOUND',
  'JOB_LOG_NOT_FOUND',
  'JOB_EXPENSE_NOT_FOUND',
  'EQUIPMENT_NOT_FOUND',
  'EQUIPMENT_LOG_NOT_FOUND',
  'RECURRING_INVOICE_NOT_FOUND',
  'CHECKPOINT_NOT_FOUND',
  'INCIDENT_NOT_FOUND',
  'PARKING_ISSUE_NOT_FOUND',
  'CHECKPOINT_SCAN_NOT_FOUND',
  'FORM_SCHEDULED_REPORT_NOT_FOUND',
  'CONTACT_LOG_NOT_FOUND',
];

export const ROLES = {
  admin: 'admin',
  business_admin: 'business_admin',
  business_company: 'business_company',
  manager: 'manager',
  employee: 'employee',
  support: 'support',
};

export const DEFAULT_MAP_ZOOM = 15;

// Palo Alto, California
export const DEFAULT_MAP_CENTER = {
  lat: 37.43940279972828,
  lng: -122.14167226540688,
};

export const CIRCLE_OPTIONS = {
  fillOpacity: 0.1,
  fillColor: '#1493ff',
  strokeColor: '#1B96FF',
  strokeOpacity: 0.5,
  strokeWeight: 1.2,
};

export const MARKS_FOR_CIRCLE_SLIDER = {
  50: '50m',
  1500: '1500m',
};

export const MIN_SLIDER_VALUE = 50;
export const MAX_SLIDER_VALUE = 1500;

export const GOOGLE_ADDRESS_PARTS = {
  STREET_NUMBER: 'street_number',
  ROUTE: 'route',
  CITY: 'locality',
  STATE: 'administrative_area_level_1',
  COUNTRY: 'country',
  POSTAL_CODE: 'postal_code',
};

export const PERMISSION_KEYS = {
  ACTIVITY: 'activity',
  SCHEDULE: 'shifts',
  COMPANIES: 'companies',
  CONTACTS: 'contacts',
  CALLBACKS: 'callbacks',
  LOCATIONS: 'locations',
  LOCATIONS_DOCS: 'locationDocs',
  JOBS: 'jobs',
  JOB_RATES: 'jobRates',
  JOB_GROUPS: 'jobGroups',
  JOB_LOGS: 'jobLogs',
  JOB_EXPENSES: 'jobExpenses',
  EMPLOYEES: 'employees',
  EMPLOYEE_GROUPS: 'employeeGroups',
  PERFORMANCE_LOG: 'perfomanceLogs',
  LEAVE_REQUESTS: 'leaveRequests',
  EMPLOYEE_RATES: 'employeeRates',
  TRAININGS_AND_LICENSES: 'trainingsAndLicenses',
  FORMS: 'forms',
  FORM_VIEW: 'formView',
  FORM_RESULTS: 'formResults',
  CHECKPOINTS: 'checkpoints',
  CHECKPOINT_SETTINGS: 'checkpointSettings',
  GPS: 'gps',
  TODOS: 'todos',
  MAP_VIEW: 'mapView',
  CALENDAR: 'calendar',
  REPORTS: 'reports',
  SETTINGS: 'settings',
  PAYMENTS: 'payments',
  PREFERENCES: 'preferences',
  PAYROLL: 'payroll',
  EQUIPMENT: 'equipment',
  INVOICES: 'invoices',
  HELP: 'help',
  FEEDBACK: 'feedback',
  INTEGRATIONS: 'integrations',
  ACTION_LOGS: 'actionLogs',
  IMPLEMENTATION_PLANS: 'implementationPlans',
  LOCATION_RATING: 'locationRatings',
  LOCATION_RATING_MAIN_COMPANY: 'locationRatingMainCompany',
  PAYROLL_ADJUSTMENT: 'payrollAdjustments',
  FORM_SCHEDULE_REPORTS: 'formScheduledReports',
  CONTACT_LOGS: 'contactLogs',
} as const;

export const COMPANY_TYPE = {
  business: 'business',
  myCompany: 'my_company',
};

export const DEF_MANAGER_PERM = {
  implementationPlan: 'create,delete,edit,read',
  checkpoints: 'create,delete,edit,read',
  contacts: 'create,delete,edit,read',
  callbacks: 'read',
  employees: 'create,delete,edit,read',
  employeeGroups: 'read',
  employeeRates: '',
  todos: 'create,delete,edit,read',
  formResults: 'create,delete,edit,read',
  forms: 'create,delete,edit,read',
  formView: 'create,delete,edit,read',
  jobGroups: 'read',
  jobLogs: 'create,delete,edit,read',
  jobRates: '',
  jobExpenses: 'create,delete,edit,read',
  jobs: 'create,delete,edit,read',
  leaveRequests: 'create,delete,edit,read',
  companies: 'create,delete,edit,read',
  locations: 'create,delete,edit,read',
  locationDocs: '',
  perfomanceLogs: 'create,delete,edit,read',
  shifts: 'create,delete,edit,read',
  trainingsAndLicenses: 'create,delete,edit,read',
  equipment: 'read',
  reports: 'read',
  invoices: '',
  help: 'read',
  feedback: 'read',
  integrations: '',
  actionLogs: '',
  locationRatings: '',
  locationRatingMainCompany: 'create,delete,edit,read',
  checkpointSettings: '',
  payrollAdjustments: '',
  formScheduledReports: '',
  contactLogs: 'create,delete,edit,read',
};

export const DEF_EMPLOYEE_PERM = {
  implementationPlan: '',
  checkpoints: '',
  contacts: '',
  callbacks: '',
  employees: '',
  employeeGroups: '',
  employeeRates: '',
  todos: '',
  formResults: '',
  forms: '',
  formView: '',
  jobGroups: '',
  jobRates: '',
  jobLogs: '',
  jobs: '',
  leaveRequests: '',
  companies: '',
  locations: '',
  locationDocs: '',
  perfomanceLogs: '',
  shifts: '',
  trainingsAndLicenses: '',
  equipment: '',
  invoices: '',
  help: '',
  feedback: '',
  integrations: '',
  actionLogs: '',
  locationRatings: '',
  locationRatingMainCompany: '',
  checkpointSettings: '',
  payrollAdjustments: '',
  formScheduledReports: '',
  contactLogs: '',
};

export const DEF_ADMIN_PERM = {
  implementationPlan: 'create,delete,edit,read',
  activity: 'read',
  shifts: 'create,read,edit,delete',
  companies: 'create,read,edit,delete',
  contacts: 'create,read,edit,delete',
  callbacks: 'create,read,edit,delete',
  locations: 'create,read,edit,delete',
  locationDocs: 'create,read,edit,delete',
  jobs: 'create,read,edit,delete',
  jobRates: 'create,read,edit,delete',
  jobGroups: 'create,read,edit,delete',
  jobLogs: 'create,read,edit,delete',
  employees: 'create,read,edit,delete',
  employeeGroups: 'create,read,edit,delete',
  perfomanceLogs: 'create,read,edit,delete',
  leaveRequests: 'create,read,edit,delete',
  employeeRates: 'create,read,edit,delete',
  trainingsAndLicenses: 'create,read,edit,delete',
  forms: 'create,read,edit,delete',
  formView: 'create,read,edit,delete',
  formResults: 'create,read,edit,delete',
  checkpoints: 'create,read,edit,delete',
  gps: 'read',
  todos: 'create,read,edit,delete',
  mapView: 'read',
  calendar: 'read',
  reports: 'create,read,edit,delete',
  settings: 'create,read,edit,delete',
  payroll: 'create,read,edit,delete',
  equipment: 'create,read,edit,delete',
  invoices: 'create,read,edit,delete',
  help: 'read',
  feedback: 'create,read,edit,delete',
  integrations: 'create,read,edit,delete',
  actionLogs: 'read,edit',
  locationRatings: '',
  locationRatingMainCompany: 'create,delete,edit,read',
  checkpointSettings: 'create,delete,edit,read',
  payrollAdjustments: 'create,delete,edit,read',
  formScheduledReports: 'read',
  contactLogs: 'create,read,edit,delete',
};

export const PASSWORD_PLACEHOLDER = '\u2022\u0020'.repeat(14);

//CALENDAR
export const CALENDAR_FORMATS = {
  DATE_RANGE_MONTH: 'MMMM YYYY',
  DATE_RANGE_WEEK_START: 'DD-MMM',
  DATE_RANGE_WEEK_END: DATE_FORMAT,
  TABLE_HEADER_DATES_MONTH_VIEW: 'dddd',
  TABLE_HEADER_DATES_WEEKS_VIEW: LONG_DATE_FORMAT,
  AGENDA_DATES: 'MMMM DD, dddd',
};

type ViewT = {
  [key: string]: View;
};

export const CALENDAR_VIEWS: ViewT = {
  WEEK: 'week',
  MONTH: 'month',
  AGENDA: 'agenda',
};

type NavigateActionT = {
  [key: string]: NavigateAction;
};

export const CALENDAR_ACTIONS: NavigateActionT = {
  PREV: 'PREV',
  NEXT: 'NEXT',
  TODAY: 'TODAY',
  DATE: 'DATE',
};

// GPS
//in meters
export const CLUSTER_RADIUS = 0;

export const MARKER_COLORS = {
  locationMarkerInRadius: '#1B96FF',
  locationMarkerOutRadius: '#DC251C',
  checkpointsScanMarker: '#65bf73',
  checkInMarker: '#ff8000',
};
export const PLATFORM_TYPES = {
  quickbooksOnline: 'quickbooksOnline',
  xero: 'xero',
};

export const IMAGE_TYPES = ['png', 'jpg', 'jpeg'];
export const DOWNLOAD_FILES = ['ppt', 'pptx', 'xls', 'xlsx', 'doc', 'docx'];
export const IMAGE_TYPES_CHECKPOINTS = [...IMAGE_TYPES, 'gif', 'svg'];

export const STATUS_TAG_COLOR = '#2E3A59';
export const ALERTS_ICON_COLOR = '#096dd9';

export enum STATUSES {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}
